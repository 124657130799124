@import "~@kopjra/uikit/dist/src/css/mainUIStyle";
@import "~react-circular-progressbar/dist/styles.css";

//.dark {
//  background-color: #363D49;
//}

//.progress {background: #5198F4; border: 0 solid rgba(223, 231, 245, 1); border-radius: 4px; height: 5px;}
//.progress-bar {background: #DFE7F5;}

.bigNumber {
  font-weight: normal;
  font-size: 50px;
}

.sessionLabel {
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #8C9AB1;
}

.Resizer {
  background: #000;
  opacity: .2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}
.Resizer.disabled {
  cursor: not-allowed;
}
.Resizer.disabled:hover {
  border-color: transparent;
}

.parent {
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;
}

.preview {
  background-color: #8C9AB1;
  position: relative;
}

.wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.inner {
  padding: 10px;
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

.inner .title {
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #8C9AB1;
  margin-bottom: 20px;
}

.inner .label {
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: #666;
  margin-bottom: 5px;
}

.inner .value {
  font-size: 13px;
  color: #555;
  margin-bottom: 5px;
}

.file-browser {
  thead tr {
    border-bottom-width: 2px;
  }
  tr {
    border-bottom: 1px solid #ddd;
  }
  tr.folder {
    cursor: pointer;
  }
  th {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    color: #666;
  }
  td {
    font-size: 13px;
    color: #555;
  }
  td, th {
    padding: 10px;
  }
  tr.file-row:hover {
    background-color: #F2F7FF;
  }
  tr.file-row {
    cursor: pointer;
  }

  tr.file-selected-row:hover {
    background-color: #efefef;
  }
  tr.file-selected-row {
    background-color: #efefef;
  }
}

.no-file {
  color: rgba(255,255,255,0.5);
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}

.pg-viewer-wrapper {
  overflow-y: auto !important;
}

div.react-lazylog {
  overflow: auto !important;
}

div.react-lazylog > div {
  overflow: initial !important;
}

.videoWrapper {
  height: 0;
  width: 100%;
  margin: 0 auto;
}

.videoWrapper > div {
  height: auto !important;
  width: auto !important;
}

.videoWrapper video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.inner.preview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.checkCol {
  input {
    margin-top: 3px;
  }
}

@media (min-height: 800px) {
  .createLogo {
    display: inherit;
    margin-top: 150px;
  }
  .createTitle {
    margin-top: 40px;
  }
}

@media (max-height: 799px) {
  .createLogo {
    display: none;
  }
  .createTitle {
    margin-top: 100px;
  }
}

.guacDisplay button.btn-secondary, .errorDisplay button.btn-secondary {
  color: #363D49;
  background-color: #DFE7F5;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2.1px;
  font-size: 14px;
}

.errorDisplay button.btn-primary {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2.1px;
  font-size: 14px;
  padding: 20px 40px;
}

.compatible-min {
  font-weight: bold;
  font-size: 12px;
  padding: 20px 40px;
  color: #fd7e14;
  text-align: center;
}

.compatible-no {
  font-weight: bold;
  font-size: 12px;
  padding: 20px 40px;
  color: #EF615E;
  text-align: center;
}

.compatible-subtitle {
  font-weight: normal;
  font-size: 12px;
  padding: 20px 40px;
  color: #DFE7F5;
  text-align: center;
}

.compatibility-title {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2.1px;
  font-size: 10px;
  padding: 10px 25px;
  color: #FFFFFF;
}

.compatibility-browser {
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2.1px;
  font-size: 10px;
  padding: 10px 25px;
  color: #FFFFFF;
}

.compatibility {
  a {
    .browserName, .browserVersion {
      color: #5198F4;
    }
    text-decoration: underline;
  }
  .envName {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-size: 10px;
    padding: 5px 30px;
    color: #8C9AB1;
    overflow: visible;
    word-break: keep-all;
    white-space: nowrap;
  }
  .browserName {
    font-size: 10px;
    padding: 5px 5px;
    color: #DFE7F5;
  }
  .browserVersion {
    font-size: 10px;
    padding: 5px 0;
    color: #DFE7F5;
  }
  margin-bottom: 30px;
}

.errorPage {
  margin-top: 100px;
  text-align: center;
  color: #363D49;

  .error {
    text-transform: uppercase;
    letter-spacing: 2.1px;
    font-size: 20px;
  }

  .error:before {
    content: "·";
    font-weight: bold;
  }

  .error:after {
    content: "·";
    font-weight: bold;
  }

  .code {
    text-transform: uppercase;
    letter-spacing: 2.1px;
    font-size: 120px;
    font-weight: bold;
  }

  .message {
    border-top: #363D49 solid 1px;
    padding-top: 10px;
    text-transform: uppercase;
    letter-spacing: 2.1px;
    font-size: 14px;
  }
}

.right {
  text-align: right;
}

.landing {
  .btn {
    padding: 15px 25px;
    font-family: KDefaultFont, sans-serif;
    font-size: 16px;
    letter-spacing: 2.4px;
    font-weight: 400;
    text-transform: uppercase;
    position: relative;
  }

  .appName {
    font-family: KDefaultFont, sans-serif;
    letter-spacing: 5.2px;
    font-size: 20px;
    color: #12181e;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    margin-top: 10px;
  }

  h4 {
    font-family: KTextFont, sans-serif;
    font-size: 20px;
    font-weight: 300;
    color: #363d49;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 15px;
    text-transform: none;
  }

  .call {
    font-size: 22px;
    letter-spacing: 1.1px;
    color: $secondary;
  }

  a.link {
    text-decoration: underline;
    font-weight: 600;
  }
}

.center-preview-message {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  *:first-child {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: $light !important;
    opacity: 0.9;
    height: auto;

    a {
      color: $light !important;
      font-weight: bold;
    }
  }
}

p.medium {
  font-size: 14px;
}

.small .kptableholder .kptablerow {
  min-height: 0;
}

#mhtmlRoot {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

replay-web-page {
  background-color: $light;
  overflow: auto;
  font-family: KDefaultFont, sans-serif;
}

.customOpener {
  font-size: 16px;
  color: $lightish;
  cursor: pointer;
  position: relative;
  z-index: 100;
  background-color: $dark;
  letter-spacing: 1.2px;

  &:hover {
    color: $primary;
  }
}

.customizationContainer {
  position: relative;
  > div {
    position: relative;
    top: -33px;
    border: solid 1px $lightish;
    padding: 40px 20px 10px;

    > button {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 200;
      padding: 0 !important;
      color: $lightish !important;
      font-size: 12px !important;
      text-decoration: none !important;

      &:hover {
        color: $primary !important;
      }
    }
  }
}

div.k-file {
  width: 100%;
}

.archive-link {
  .copy {
    color: $secondary;
    &:hover {
      color: $primary;
    }
  }
  .copied {
    color: $success;
  }
}

//.iframe-container {
//  position: relative;
//  width: 100%;
//  min-height: 400px;
//  border-radius: 5px;
//
//  iframe {
//    position: absolute;
//    top: 0;
//    left: 0;
//    width: 100%;
//    height: 100%;
//    border-radius: 3px;
//  }
//}

.welcome {
  img.tip {
    border: 1px solid #707070;
  }

  .iframe-container iframe {
    border: 1px solid #707070;
  }

  h1 {
    font-family: KTextFont, sans-serif;
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 0.3px;
    color: $secondary;
  }

  h3 {
    font-family: KTextFont, sans-serif;
    font-size: 22px;
    letter-spacing: 1.1px;
    font-weight: normal;
    text-transform: none;
    color: $secondary;
    margin-top: 20px;
  }

  .btn.btn-nm {
    padding: 20px;
  }

  p {
    font-family: KTextFont, sans-serif;
    font-weight: 400;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 27px;
    text-align: center;
  }

  .title-separator {
    height: 3px;
    width: 3em;
    background: #cdcdcd;
    border-bottom: 1px solid #cdcdcd;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .carousel-indicators {
    bottom: -50px;

    button {
      background-color: $cmp-bg !important;
      opacity: 1 !important;
      width: 30px !important;
      height: 2px !important;
      margin-left: 10px !important;
      margin-right: 10px !important;
    }

    .active {
      background-color: $primary !important;
    }
  }

  .carousel-control-prev, .carousel-control-next {
    display: none !important;
  }
}

.darkmode label span {
  color: #8c9ab1 !important;
}
